import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Hero from '../components/Shared/Hero'
import Search from '../components/Shared/Search'
import Personas from '../components/Shared/Personas'
import Space from '../components/Shared/Space'
import Event from '../components/Shared/Event'
import Summer from '../components/Shared/Summer'
import Logement from '../components/Shared/Logement'
import Location from '../components/Shared/Location'
import Services from '../components/Shared/Services'
import CartService from '../components/Shared/CartService'
import Gallery from '../components/Shared/Gallery'
import Testimonial from '../components/Shared/Testimonial'
import Questionnaires from '../components/Shared/Questionnaires'
import * as styles from './styles/residence.module.scss'
import { Helmet } from 'react-helmet'
import Instagram from '../components/Shared/Instagram'
import DecoBigIllus from '../components/Illus/DecoBigIllus'
import Meta from '../components/Core/Meta'
import { useLocation } from '@reach/router'
import AnchorNav from '../components/Core/AnchorNav'
import { useTranslation } from '../hooks/useTranslation'
import BlockSeoText from '../components/Shared/BlockSeoText'
import Breadcrumb from '../components/Core/Breadcrumb'
import slugify from 'slugify'
import { head } from '../components/head'
import usePageView from '../hooks/usePageView'
import { removeLastSlugSection } from '../utils/mixins'
import Modal from '../components/Core/Modal'
import ModalGeneral from '../components/Shared/ModalGeneralContent'

export const Head = head('umbracoResidencePage')

export default function ResidenceDetail(props: any) {
  const { data } = props
  const page = data.umbracoResidencePage
  const instagramData = data.umbracoInstagram
  const pageTitle = page?.metaTitle
    ? page?.metaTitle
    : page?.title
    ? page?.title.replace(/\n/g, '')
    : 'Residence'

  const popinGeneralVisible = page?.popinGeneralVisible

//   console.log(data)
  // console.log("ResidenceDetail :", page)
  const [showGeneral, setShowGeneral] = useState<boolean>(false)

  const allPageIntl = data.allPageIntl.edges
  const listingPage = data.umbracoListingResidencePage

  const intlListingPage = allPageIntl.find(
    (page: any) => page.node && page.node.name === listingPage?.name
  )?.node

  const loc = useLocation()
  const [cityNameFromUrl, setCityNameFromUrl] = useState('')
  const [visitVirtuel, setvisitVirtuel] = useState('')
  const { t } = useTranslation('residence')

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)

    let baseName = location.pathname
      .replace(slugify(page.name, { lower: true }), '')
      .replace('/en', '')
      .split('/')[2]

    if (baseName) {
      baseName = `${baseName[0].toUpperCase()}${baseName
        .slice(1)
        .toLowerCase()}`
      setCityNameFromUrl(baseName.replace('-', ' '))
    }

    if (typeof window !== 'undefined' && window.document) {
      usePageView(pageTitle, window.location.pathname)
       if (
        popinGeneralVisible &&
        !sessionStorage.getItem('page_' + page.id + '_already_shown')
      ) {
        setTimeout(() => {
          setShowGeneral(true)
        }, 2000)
      }
    }

    if(page.items && visitVirtuel == ''){
      for (let index = 0; index < page.items.length; index++) {
        const item =  page.items[index];
        if(item.content){
          const blockContent = item.content
          setvisitVirtuel(blockContent.virtualVisitUrl)
          break;
        }
      }
    }
  }, [])
  // let visitVirtuel = ''
  console.log(location.pathname, page.name)

  const hideModal = () => {
    setShowGeneral(false)
    sessionStorage.setItem('page_' + page.id + '_already_shown', 'true')
  }

  return (
    <>
      <Helmet>
        <body className="single-residence" />
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'RealEstateAgent',
            name: page.title,
            image:
              page.imageCover && page.imageCover.childImageSharp
                ? page.imageCover.childImageSharp.gatsbyImageData.images
                    .fallback.src
                : '',
            '@id': page.title,
            url: loc?.href,
            telephone: page.telephone,
            address: {
              '@type': 'PostalAddress',
              streetAddress: page.streetAddress,
              addressLocality: page.addressLocality,
              postalCode: page.postalCode,
              addressCountry: page.addressCountry,
            },
            geo: {
              longitude: page.longitude,
              latitude: page.latitude,
            },
          }),
        }}
      ></script>
      <Layout
        className={styles.residenceDetail}
        canDisplayPopin={popinGeneralVisible}
      >
        <Hero
          title={page.title.replace(/\n/g, '<br>')}
          image={page.imageCover}
          image-mobile={page.imageCoverMobile}
          video={page.videoCover}
          visitVirtuelUrl={visitVirtuel}
        >
          {/* <Search /> */}
          <DecoBigIllus className="deco" />
        </Hero>
        {page.items && <AnchorNav data={page.items} />}
        {page.items &&
          page.items.map((homeBlock: any, index: number) => {

            if (homeBlock.content) {
              const blockContent = homeBlock.content
              switch (blockContent.contentTypeAlias) {
                case 'residencePush':
                  // if(!visitVirtuel){
                  //   visitVirtuel = blockContent.virtualVisitUrl
                  // }
                  return (
                    <Summer
                      data={{
                        contentTypeAlias: blockContent.contentTypeAlias,
                        title: blockContent.title,
                        paragraph: blockContent.subtitle,
                        link: blockContent.links
                          ? blockContent.links[0]
                          : {
                              name: 'Contacter la residence',
                              url: '/',
                            },
                        position: page.title,
                        cta: blockContent.virtualVisit
                          ? {
                              title: 'VISITE VISTUELLE',
                              path: '',
                            }
                          : undefined,
                        virtualVisit: blockContent.virtualVisit,
                        virtualVisitUrl: blockContent.virtualVisitUrl,
                      }}
                      illustration={blockContent.illustration}
                      residence={page}
                      blockClassName={`summer-push ${
                        index === 0 ? 'concept-push' : ''
                      }`}
                      bg={blockContent.backgroundColor}
                      titleColor={blockContent.titleColor}
                      key={`sp-${index}`}
                    />
                  )
                case 'blockPersonas':
                  return (
                    <Personas
                      data={{
                        contentTypeAlias: blockContent.contentTypeAlias,
                        title:
                          blockContent.title || 'Le coliving twiste pour vous',
                        description:
                          blockContent.subtitle ||
                          `Prenez le temps d’être en mouvement ! Twist by Kley, c’est le
              nouveau lieu de vie idéal des jeunes actifs pour travailler,
              découvrir, se recentrer, s'épanouir et rejoindre une véritable
              communauté !`,
                        personas: blockContent.itemsPersona,
                      }}
                      residence={page}
                      key={`p-${index}`}
                      className="persona"
                    />
                  )
                case 'blockAccomodations':
                  return (
                    <Logement
                      visitVirtuelUrl={visitVirtuel}
                      data={blockContent}
                      key={`r-${index}`}
                      residence={page}
                    />
                  )
                case 'blockIncludedServices':
                  return <Services data={blockContent} key={`r-${index}`} />
                case 'blockConcept1':
                  return (
                    <Space
                      data={blockContent}
                      key={`s-${index}`}
                      className="space"
                      visitVirtuelUrl={visitVirtuel}
                    />
                  )
                case 'blockPaidServices':
                  return <CartService data={blockContent} key={`cs-${index}`} />
                case 'imagesGallery':
                return <Gallery data={blockContent} visitVirtuelUrl={visitVirtuel} key={`g-${index}`} />
                case 'blockNewEvents':
                  return <Event data={blockContent} key={`be-${index}`} />
                case 'blockLocation':
                  return (
                    <Location
                      data={Object.assign({}, blockContent, {
                        contact: page.telephone,
                      })}
                      pois={page.newPoiLists}
                      gps={{ lat: +page.latitude, lng: +page.longitude }}
                      key={`bl-${index}`}
                      className={styles.location}
                    />
                  )
                case 'blockTestimonials':
                  return <Testimonial data={blockContent} key={`bt-${index}`} />
                case 'blockFAQ':
                  return (
                    <Questionnaires data={blockContent} key={`bf-${index}`} />
                  )
              }
            }

            return null
          })}

        {page.bottomSEOText && <BlockSeoText content={page.bottomSEOText} />}
        {instagramData.enabled && <Instagram data={instagramData} />}

        <Breadcrumb
          data={[
            {
              title:
                intlListingPage?.name || t('Listing residence', 'residence'),
              link: intlListingPage?.uri,
              id: '' + intlListingPage?.uri,
            },
            {
              title: cityNameFromUrl,
              link: removeLastSlugSection(location.pathname).replace('//', '/'),
              id: removeLastSlugSection(location.pathname).replace('//', '/'),
            },
            {
              title: page.title,
              link: location.pathname,
              id: '' + location.pathname,
            },
          ]}
        />
        <div id="modal-single-residence">
          { popinGeneralVisible && page.popinToDisplay && (
            <Modal
              data={page.popinToDisplay}
              visible={showGeneral}
              onClose={hideModal}
              width={'1018px'}
              secondaryBg="FFFFFF"
              size="lg"
              general
              darkClose
            >
              <ModalGeneral data={page.popinToDisplay} />
            </Modal>
          )}
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($name: String, $lang: String) {
    umbracoListingResidencePage(lang: { eq: $lang }) {
      name
      _url
      _urls {
        fr
        en_us
      }
    }
    umbracoResidencePage(name: { eq: $name }, lang: { eq: $lang }) {
      id
      name
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      bottomSEOText
      _id
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      _url
      _urls {
        fr
        en_us
      }
      title
      shortDescription
      shortName
      telephone
      streetAddress
      addressLocality
      postalCode
      addressCountry
      longitude
      latitude
      city {
        title
      }
      imageCover {
        childImageSharp {
          id
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      imageCoverMobile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      videoCover {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      tag {
        content {
          color
          title
          contentTypeAlias
        }
      }
      newPoiLists {
        content {
          title
          icon
          items {
            content {
              gps {
                mapconfig {
                  centerCoordinates {
                    lat
                    lng
                  }
                }
              }
              title
              address
              contact
            }
          }
        }
      }
      popinGeneralVisible
      popinToDisplay {
        _id
        name
        popinType
        subtitle
        stayTitle
        staySubtitle
        isActive
        popinGTMEventName
        cta {
          name
          target
          type
          url
        }
        _urls {
          en_us
          fr
        }
        colorCombination
        contactPhoneNumber
        contentTypeAlias
        title
        _url
      }
      items {
        content {
          title
          sectionTitle
          subtitle
          contentTypeAlias
          colorCombination
          titleColor
          backgroundColor
          virtualVisit
          virtualVisitUrl
          virtualVisitButtonLabel
          illustration {
            src
            gatsbyImageData(width: 400, quality: 90)
          }
          links {
            url
            name
            type
          }
          link {
            url
            name
            type
          }
          itemsGallery {
            src
            gatsbyImageData(width: 1000, quality: 90)
            media {
              alt
              name
            }
          }
          titleGallery
          backgroundVimeoId
          itemsPaidServices {
            name
            title
            subtitle
            shortName
            contentTypeAlias
            ctaTitle
            ctaLink {
              udi
              url
              name
              type
            }
            thumbnail {
              src
              gatsbyImageData(width: 500, quality: 90)
            }
          }
          itemsIncludedServices {
            name
            title
            subtitle
            contentTypeAlias
            cTATitle
            ctaLink {
              udi
              url
              name
              type
            }
          }
          itemsFaq {
            name
            answer
            question
            contentTypeAlias
          }
          itemsPersona {
            name
            title
            subtitle
            frameColor
            contentTypeAlias
            link {
              url
              name
            }
            thumbnail {
              gatsbyImageData(width: 180, quality: 90)
              media {
                alt
              }
            }
          }
          itemsAccomodation {
            name
            title
            subtitle
            priceFrom
            priceFromValue
            roomSize
            contentTypeAlias
            shortDescription
            accomodationTag
            accomodationType {
              accomodationTypeTitle
            }
            thumbnailImages {
              gatsbyImageData
              media {
                alt
                name
                _url
              }
            }
            details {
              title
              boTitle
              subtitle
            }
            accommodationDetailModalDescription
            accommodationDetailModal {
              content {
                title
                description
              }
            }
          }
          newItemsAccomodation {
            content {
              title
              subtitle
              priceFrom
              priceFromValue
              roomSize
              contentTypeAlias
              shortDescription
              accomodationTag
              accomodationType {
                accomodationTypeTitle
              }
              thumbnailImages {
                gatsbyImageData(width: 700, quality: 90)
                media {
                  alt
                  name
                  _url
                }
              }
              details {
                title
                boTitle
                subtitle
              }
              accommodationDetailModal {
                content {
                  title
                  description
                }
              }
              accommodationDetailModalDescription
              hideOnHifeWebsite
            }
          }
          compareAccomodations {
            content {
              title
              tag
              surface
              servicesAndOptions
              plus
              capacity
              bed
              thumbnail {
                src
              }
            }
          }
          linkFaq {
            url
            name
            type
          }
          spaces {
            title
            subtitle
            name
            mainColor
            ctaTitle
            ctaLink {
              udi
              url
              name
              type
            }
            logo {
              src
            }
            medias {
              src
              gatsbyImageData(width: 500, quality: 90)
            }
          }
          eventsList {
            title
            subtitle
            bg
            thumbnail {
              media {
                alt
                _url
              }
              gatsbyImageData(width: 500, quality: 90)
            }
            backgroundVimeoId
            category {
              color
              title
            }
            price
          }
          keyPoints {
            content {
              title
              paragraph
            }
          }
          acces {
            content {
              title
              subtitle
              transportType
              descriptionTransport
            }
          }
          illustration {
            src
          }
          linkFaq {
            name
            url
          }
          testimonials {
            content {
              media {
                src
                media {
                  alt
                }
                gatsbyImageData(width: 1560, quality: 90)
              }
              author
              authorTitle
              paragraph
              vimeoId
            }
          }
        }
      }
    }
    umbracoInstagram(lang: { eq: $lang }) {
      title
      hashtag
      instagramHandle
      instagramLinkButton {
        url
        name
        type
      }
      enabled
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`
